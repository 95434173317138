import React from 'react';
import { Trans, withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';

import Button from '../components/Button';

function NotFoundPage(props) {
  const { pageContext, data } = props;
  const langPrefix = prefix(pageContext.lang);
  return (
    <div className="page page--404 container">
      <h1 className="page__title">
        <Trans>404</Trans>
        <div className="line"></div>
      </h1>
      <p className="page__subtitle">
        <Trans>
        Page request error. Sorry, the page you’re looking for doesn’t exist.<br /> Head back home to discover more.
        </Trans>
      </p>
      <Button target="_blank" href={`/`}>Return to home</Button>
    </div>
  );
}

export default withI18n()(NotFoundPage);
